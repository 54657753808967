import React, {  useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { isRLTLang } from "src/i18n";
import { useFormikContext } from "formik";
import _ from "lodash"
import useResetDateRangePickerField from "./hooks/useResetDateRangePickerField";
import useMomentLocale from "./hooks/useMomentLocale";
import useResizeHook from "./hooks/useResizeHook";
import moment from "moment";

moment.updateLocale('fr', {
  invalidDate: 'date invalide'
});
moment.updateLocale('ar-tn', {
  invalidDate: 'تاريخ غير صالح'
});

const DateRangePickerField = ({
  form,
  label,
  field,
  size = 12,
  required=false,
  inputGroupClassName = "form-group",
  format = "DD/MM/YYYY",
  emit,
  ...props
}) => {
  useMomentLocale()
  const formik = useFormikContext()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  


  // custom hook to enable resetting the field when form resets 
  useResetDateRangePickerField({ formik, fieldName:field.name,setStartDate,setEndDate,format})
  
  const [width]  = useResizeHook()
  const renderControls = () => {
    return (
      // <section className="calendar_controls">
      //   <span onClick={()=>{
      //       setStartDate( moment().startOf("week"));
      //       setEndDate(moment().endOf("week"));
      //       form.setFieldValue("startDate", moment().startOf("week").format(format));
      //       form.setFieldValue("endDate", moment().endOf("week").format(format));
      //   }}>This week</span>
      //   <span onClick={()=>{
      //       setStartDate( moment().startOf("month"));
      //       setEndDate(moment().endOf("month"));
      //       form.setFieldValue("startDate", moment().startOf("month").format(format));
      //       form.setFieldValue("endDate", moment().endOf("month").format(format));
      //   }}>this month</span>
      // </section>
      <div
      type="button"
      className="d-flex justify-content-end"
      onClick={()=>setFocusedInput(null)}
    style={{
      border: '1px solid #dce0e0',
      backgroundColor: '#fff',
      color: '#484848',
      padding: '3px',
    }}
  >'X'</div>
    );
  };
  return (
    <div
    className={ inputGroupClassName  + ` col-lg-${size}`}    >
    {label && (
      <label className="custom-label-style">

        {label} <span>{required && "*"}</span>
      </label>
    )}
    <DateRangePicker
      // keepOpenOnDateSelect
      // renderCalendarInfo={renderControls}
      monthFormat="MMMM YYYY"
      renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: -10,
          }}
        >
          <div className="d-flex flex-row flex-1 justify-content-center gaps-16">
          <div type="button"
              onClick={() => {
                onYearSelect(month, month.year() - 10);
              }}
            >
              ↡
            </div>
            <div type="button"
              onClick={() => {
                onYearSelect(month, month.year() - 1);
              }}
            >
              ▼
            </div>
            <span>{month.year()}</span>
            <div type="button"
              onClick={() => {
                onYearSelect(month, month.year() + 1);
              }}
            >
              ▲
            </div>
            <div type="button"
              onClick={() => {
                onYearSelect(month, month.year() + 10);
              }}
            >
              ↟
            </div>
          </div>
          <div>
            <span>{month.format("MMM")}</span>
          </div>
        </div>
      )}
      isOutsideRange={(day) => day.isAfter(moment(), 'day')}
      readOnly={true}
      inputIconPosition="after"
      showDefaultInputIcon
      hideKeyboardShortcutsPanel={true}
      enableOutsideDays={true}
      orientation={width > 700 ? "horizontal" : "vertical"}
      anchorDirection={isRLTLang() ? "right" : "left"}
      isRTL={isRLTLang()}
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        emit({type:"CHANGE_START_DATE",payload:moment(startDate).format(format)})
        emit({type:"CHANGE_END_DATE",payload:moment(endDate).format(format)})
        form.setFieldValue("startDate", moment(startDate).format(format));
        form.setFieldValue("endDate", moment(endDate).format(format));

      }} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
      minimumNights={0}
    />
    </div>
  );
};

export default DateRangePickerField;
