import { pages as homeRoutes } from './containers/home/routes'
import { pages as profileRoutes } from './containers/profile/routes'
import { pages as childrenRoutes } from './containers/childrenSpace/routes'
import { pages as vaccinationRoutes } from './containers/vaccination/routes'
import { pages as specialtiesRoutes } from './containers/specialties/routes'
import { pages as mapiRoutes } from "./containers/mapi/routes"
import { pages as listRoutes } from './containers/list/routes'
import {pages as DCIRoutes} from './containers/dci/routes'
import {pages as CitoyenRoutes} from './containers/citoyen/routes'


// Combined routes
export default {
  ...homeRoutes,
  ...profileRoutes,
  ...childrenRoutes,
  ...vaccinationRoutes,
  ...DCIRoutes,
  ...mapiRoutes,
  ...listRoutes,
  ...specialtiesRoutes,
  ...CitoyenRoutes,

}
