import { ACTIONS, ENDPOINTS } from "../constants"
import { CALL_API, HTTP_METHODS } from "../../../../../../constants"


export const clearStore = () =>
({
     type : ACTIONS.CLEAR_DCI
})


export const fetchDcis = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init : ACTIONS.FETCH_DCIS_INIT,
      success : ACTIONS.FETCH_DCIS_SUCCEDED,
      fail : ACTIONS.FETCH_DCIS_FAILED
    },
    endpoint: ENDPOINTS.DCIS,
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})

export const createDci = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
        init : ACTIONS.CREATE_DCI_INIT,
        success : ACTIONS.CREATE_DCI_SUCCEDED,
        fail : ACTIONS.FETCH_DCI_FAILED
    },
    endpoint: ENDPOINTS.DCI_CREATE,
    method: HTTP_METHODS.POST,
    auth: true
  }
})

export const editDci = ({ param }, payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
        init : ACTIONS.EDIT_DCI_INIT,
        success : ACTIONS.EDIT_DCI_SUCCEDED,
        fail : ACTIONS.EDIT_DCI_SUCCEDED
    },
    endpoint: ENDPOINTS.DCI.replace(":param", param),
    method: HTTP_METHODS.PATCH,
    auth: true
  }
})

export const activateDci = ({ param }) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.ACTIVATE_DCI_INIT,
      success: ACTIONS.ACTIVATE_DCI_SUCCEDED,
      fail: ACTIONS.ACTIVATE_DCI_FAILED
    },
    endpoint: ENDPOINTS.DCI_ACTIVATE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})


export const deactivateDci = ({ param }) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DEACTIVATE_DCI_INIT,
      success: ACTIONS.DEACTIVATE_DCI_SUCCEDED,
      fail: ACTIONS.DEACTIVATE_DCI_FAILED
    },
    endpoint: ENDPOINTS.DCI_DEACTIVATE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})


export const fetchDci = ({ param }) =>
({
  type: CALL_API,
  meta: {
    actions: {
        init : ACTIONS.FETCH_DCI_INIT,
        success : ACTIONS.FETCH_DCI_SUCCEDED,
        fail : ACTIONS.FETCH_DCI_FAILED
    },
    endpoint: ENDPOINTS.DCI.replace(":param", param),
    method: HTTP_METHODS.GET,
    auth: true
  }
})

export const fetchSpecialityDcis = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
        init : ACTIONS.FETCH_SPECIALITY_DCI_INIT,
        success : ACTIONS.FETCH_SPECIALITY_DCI_SUCCEDED,
        fail : ACTIONS.FETCH_SPECIALITY_DCI_FAILED
    },
    endpoint: ENDPOINTS.SPECIALITY_DCI,
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})


export const fetchGetSpecialityDcis = (params, { param }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
          init : ACTIONS.FETCH_SPECIALITY_DCI_INIT,
          success : ACTIONS.FETCH_GET_SPECIALITY_DCI_SUCCEDED,
          fail : ACTIONS.FETCH_SPECIALITY_DCI_FAILED
      },
      endpoint: ENDPOINTS.GET_SPECIALITY_DCI.replace(":param", param),
      method: HTTP_METHODS.GET,
      params,
      auth: true
    }
  })

export const fetchFilterSpecialityDcis = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
        init : ACTIONS.FETCH_FILTER_SPECIALITY_DCI_INIT,
        success : ACTIONS.FETCH_FILTER_SPECIALITY_DCI_SUCCEDED,
        fail : ACTIONS.FETCH_FILTER_SPECIALITY_DCI_FAILED
    },
    endpoint: ENDPOINTS.FILTER_SPECIALITY_DCI,
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})