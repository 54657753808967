import { ACTIONS } from "../constants"


const initialState = {
  searchResults: [],
  searchBirthDate:null,
  structure: {},
  childrens: {},
  child: {},
  parentExist: false,
  isAuthenticated: false,
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    create: false,
    edit: false,
    fetch: false,
    delete: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {


    // create child 
    case ACTIONS.FETCH_CREATECHILDREN_INIT: {
      return { ...state, isLoading: true, isFetching: true, error: null }
    }
    
    case ACTIONS.FETCH_CREATECHILDREN_SUCCEDED: {
      return { ...state, isLoading: false, error: null, success: { ...state.success, created: true }, child: payload,evaxCode:payload.evaxNumber }
    }
    case ACTIONS.FETCH_CREATECHILDREN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }


    case ACTIONS.FETCH_EVAXSEARCH_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_EVAXSEARCH_SUCCEDED: {
      console.log(payload)
      return { ...state, isFetching: false, isLoading: false, searchResults: payload,searchBirthDate: payload.birthDate,error: null, success: initialState.success }

    }
    case ACTIONS.FETCH_EVAXSEARCH_FAILED: {
      return { ...state, isFetching: false, error: payload, searchResults: [] }
    }

    case ACTIONS.CHECK_PARENT_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.CHECK_PARENT_SUCCEDED: {
      // console.log(payload)
      return { ...state, isLoading: true, parentExist: true }

    }
    case ACTIONS.CHECK_PARENT_FAILED: {
      return { ...state, parentExist: false, isFetching: false }
    }


    case ACTIONS.CLEAR_CHILDREN: {
      return { ...state, isLoading: false, error: null ,searchResults : [],parentExist:false,success:{create:false,edit:false,fetch:false},evaxCode:"" }
    }
    case ACTIONS.DELETE_CHILD_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.DELETE_CHILD_SUCCEDED: {
      console.log(payload)
      return { ...state, searchResults: [], isLoading: false, error: null, success: { ...state.success, delete: true } }
    }
    case ACTIONS.DELETE_CHILD_FAILED: {
      return { ...state, isLoading: false, error: null }
    }

    case ACTIONS.LINK_CHILD_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.LINK_CHILD_SUCCEDED: {
      return { ...state, isLoading: false, error: null, success: { ...state.success, create: true, evaxCode:payload.evaxNumber } }
    }
    case ACTIONS.LINK_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    // create foreign child 
    case ACTIONS.FETCH_CREATE_FOREIGN_CHILDREN_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.FETCH_CREATE_FOREIGN_CHILDREN_SUCCEDED: {
      return { ...state, isLoading: false, error: null, success: { ...state.success, created: true }, child: payload,evaxCode:payload.numEvax }
    }
    case ACTIONS.FETCH_CREATE_FOREIGN_CHILDREN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.LINK_FOREIGN_CHILD_INIT: {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.LINK_FOREIGN_CHILD_SUCCEDED: {
      return { ...state, isLoading: false, error: null, success: { ...state.success, create: true } }
    }
    case ACTIONS.LINK_FOREIGN_CHILD_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }
    default: {
      return state
    }
  }
}
