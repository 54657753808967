export const FETCH_DCI_INIT = "dci@FETCH_DCI_INIT"
export const FETCH_DCI_SUCCEDED = "dci@FETCH_DCI_SUCCEDED"
export const FETCH_DCI_FAILED = "dci@FETCH_DCI_FAILED"


export const FETCH_DCIS_INIT = "dci@FETCH_DCIS_INIT"
export const FETCH_DCIS_SUCCEDED = "dci@FETCH_DCIS_SUCCEDED"
export const FETCH_DCIS_FAILED = "dci@FETCH_DCIS_FAILED"

export const FETCH_SPECIALITY_DCI_INIT = "speciality_dci@FETCH_SPECIALITY_DCI_INIT"
export const FETCH_SPECIALITY_DCI_SUCCEDED = "speciality_dci@FETCH_SPECIALITY_DCI_SUCCEDED"
export const FETCH_SPECIALITY_DCI_FAILED = "speciality_dci@FETCH_SPECIALITY_DCI_FAILED"

export const FETCH_GET_SPECIALITY_DCI_SUCCEDED = "speciality_dci@FETCH_GET_SPECIALITY_DCI_SUCCEDED"


export const FETCH_FILTER_SPECIALITY_DCI_INIT = "speciality_dci@FETCH_FILTER_SPECIALITY_DCI_INIT"
export const FETCH_FILTER_SPECIALITY_DCI_SUCCEDED = "speciality_dci@FETCH_FILTER_SPECIALITY_DCI_SUCCEDED"
export const FETCH_FILTER_SPECIALITY_DCI_FAILED = "speciality_dci@FETCH_FILTER_SPECIALITY_DCI_FAILED"

export const CLEAR_DCI = "dci@CLEAR_DCI"

export const CREATE_DCI_INIT = "dci@CREATE_DCI_INIT"
export const CREATE_DCI_SUCCEDED = "dci@CREATE_DCI_SUCCEDED"
export const CREATE_DCI_FAILED = "dci@CREATE_DCI_FAILED"


export const EDIT_DCI_INIT = "dci@EDIT_DCI_INIT"
export const EDIT_DCI_SUCCEDED = "dci@EDIT_DCI_SUCCEDED"
export const EDIT_DCI_FAILED = "dci@EDIT_DCI_FAILED"

export const DELETE_DCI_INIT = "dci@DELETE_DCI_INIT"
export const DELETE_DCI_SUCCEDED = "dci@DELETE_DCI_SUCCEDED"
export const DELETE_DCI_FAILED = "dci@DELETE_DCI_FAILED"


export const DEACTIVATE_DCI_INIT = "dci@DEACTIVATE_DCI_INIT"
export const DEACTIVATE_DCI_SUCCEDED = "dci@DEACTIVATE_DCI_SUCCEDED"
export const DEACTIVATE_DCI_FAILED = "dci@DEACTIVATE_DCI_FAILED"

export const ACTIVATE_DCI_INIT = "dci@ACTIVATE_DCI_INIT"
export const ACTIVATE_DCI_SUCCEDED = "dci@ACTIVATE_DCI_SUCCEDED"
export const ACTIVATE_DCI_FAILED = "dci@ACTIVATE_DCI_FAILED"
