import React, { useEffect } from "react";
import { isEqual } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { injectIntl } from "react-intl";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSelectRow, getHandlerTableChange } from "../../../../helpers";
import Pagination from "./Pagination";
import { SearchFilter } from "..";
import { ARCHIVED_PARAM, NOT_ARCHIVED, ARCHIVED } from "../../../../constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SearchFilterSelect from "../forms/search-filter/SearchFilterSelect";
import filterFactory from "react-bootstrap-table2-filter";
import { FormattedMessage } from "react-intl";

// import overlayFactory from "react-bootstrap-table2-overlay";
import _ from "lodash";
import LayoutHeader from "../forms/table/LayoutHeader";
const DataTable = ({
  intl,
  entities,
  columns,
  title,
  isFetching,
  isScrollable = false,
  isScrollableMap = false,
  totalSize,
  keyField = "id",
  queryParams,
  onQueryParamsChange,
  ids,
  filter,
  dispatch,
  actionType,
  filterOn = true,
  hasArchive = false,
  setIds,
  pagination = false,
  emptySelectRender=false,
  dci,
}) => {
  const applyFilter = (values) => {
    if(values?.search==="")
      values.search = undefined
    const newQueryParams = { ...queryParams, ...values };
    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1;
      newQueryParams.page = 1;
      onQueryParamsChange(newQueryParams);
    }
  };

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize,
    ...queryParams,
    paginationSize: 10,
    sizePerPageList: [
      // { text: "5", value: 5},
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "30", value: 30 },
      { text: "50", value: 50 },
    ],
  };

  let selectRow = {};
  if (ids && setIds) {
    selectRow = {
      selectRow: getSelectRow({ entities, ids, setIds, dispatch, actionType,emptySelectRender }),
    };
  }

  const onChange = () => {
    if (queryParams[ARCHIVED_PARAM]) {
      onQueryParamsChange({ ...queryParams, [ARCHIVED_PARAM]: ARCHIVED });
    } else {
      onQueryParamsChange({ ...queryParams, [ARCHIVED_PARAM]: NOT_ARCHIVED });
    }
  };
  const _title = intl.formatMessage({ id: "DCI.SPECIALTY.DETAILS" });

  const rowClasses = (row, rowIndex) => {
    if (row?.status === 1) {
      return "notDone";
    } else {
      return "";
    }
  };

  return (
    <>
      {filterOn && (
        <SearchFilter
          withoutCard
          placeholder={intl.formatMessage({
            id: "GENERAL.SEARCH",
          })}
          label={intl.formatMessage({
            id: "GENERAL.SEARCH",
          })}
          applyFilter={applyFilter}
          onlyNumber={false}
        />
      )}
      {dci && <LayoutHeader title_dci={_title} />}
      {hasArchive && (
        <div className="text-right pb-2 pt-2">
          <FormControlLabel
            control={
              <Switch
                checked={!Boolean(queryParams[ARCHIVED_PARAM])}
                onChange={onChange}
                name=""
              />
            }
            label={intl.formatMessage({ id: "GENERAL.ARCHIVED" })}
          />
        </div>
      )}
      {filter}
      {pagination ? (
        <div className="d-flex flex-column scrollable">
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={isFetching}
                  paginationProps={paginationProps}
                >
                  <LayoutHeader min_title={title} />
                  <BootstrapTable
                    pagination={paginationFactory(paginationOptions)}
                    wrapperClasses={`responsive-table ${
                      isScrollable ? "scrollable" : isScrollableMap ? "scrollable-map" : ""
                    }`}
                    classes="table table-head-custom table-vertical-center mb-0 "
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={keyField}
                    noDataIndication={() => (
                      <div className="p-4 text-center text-muted font-weight-bolder">
                        {intl.formatMessage({ id: "GENERAL.NO_DATA" })}{" "}
                      </div>
                    )}
                    data={entities}
                    // overlay={overlayFactory({
                    //   spinner: true,
                    //   background: "rgba(225,0,0,0.7)",
                    //   styles: {
                    //     overlay: (base) => ({
                    //       ...base,
                    //       background: "rgba(224, 224, 224, 0.5)",
                    //       maxHeight: "calc(100% - 63.58px)",
                    //     }),
                    //     spinner: (base) => ({
                    //       ...base,
                    //       width: "60px",
                    //       "& svg circle": {
                    //         stroke: "rgba(255, 0, 0, 0.5)",
                    //       },
                    //     }),
                    //   },
                    // })}
                    loading={isFetching}
                    rowClasses={rowClasses}
                    columns={columns}
                    onTableChange={getHandlerTableChange(onQueryParamsChange)}
                    {...selectRow}
                    filter={filterFactory()}
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </div>
      ) : (
        <BootstrapTable
          // pagination={paginationFactory(paginationOptions)}
          wrapperClasses={`responsive-table ${
            isScrollable ? "scrollable" : isScrollableMap ? "scrollable-map" : ""
          }`}
          classes="table table-head-custom table-vertical-center mb-0 "
          bootstrap4
          bordered={false}
          // remote
          keyField={keyField}
          noDataIndication={() => (
            <div className="p-4 text-center text-muted font-weight-bolder">
              {intl.formatMessage({ id: "GENERAL.NO_DATA" })}{" "}
            </div>
          )}
          data={entities}
          rowClasses={rowClasses}
          columns={columns}
          // onTableChange={getHandlerTableChange(onQueryParamsChange)}
          {...selectRow}
          filter={filterFactory()}
          // {...paginationTableProps}
        ></BootstrapTable>
      )}
    </>
  );
};

export default injectIntl(DataTable);
