export const LOGIN = "/api/auth/login"

export const LOGOUT = "/auth/logout"

export const GET_ME = "/api/profiles/profile-detail/:param"

export const FORGOT_PASSWORD = "api/auth/reset-password/change"
export const RESET_PASSWORD = "/api/auth/reset-password"
export const FETCH_TOKEN = "/api/v1/authentication/check-token/:param"

export const REGISTER = "/api/auth/register"

export const REGISTER_PRIVATE_HEALTHCARE_PROFESSIONAL = "/api/profiles/registerPrivateHealthcareProfessional"
export const CNOM_TOKEN_VERIFY = "/api/auth/cnom-token-verify"
export const CREATE_PRIVATE_HEALTHCARE_PROFESSIONAL = "/api/auth/createPrivateHealthcareProfessional"


export const VERIFY_USER = "/api/auth/reset-password/verify-user"
export const CHANGE_PASSWORD = "/api/auth/reset-password/verify-otp"

export const CHANGE_CURRENT_CENTER = "/api/current-center/change"
export const CHANGE_PHONE_NUMBER = "/api/change_phone"
export const CHANGE_PHONE_NUMBER_OTP = "/api/phone_otp"

