import React from "react"
import { injectIntl } from "react-intl"
import { Formik, Field } from "formik"
import InputAddons from "./../inputs/InputAddons"
import {ControlUIProvider} from "../../store"
import { Card, CardBody } from "../../../controls"

const SearchFilter = ({  applyFilter,pattern, maxLength,label,placeholder,type,disabled,onlyNumber=true,dispatchedAction,withoutCard=false }) => {
  return (
    <ControlUIProvider>
      <Formik
        initialValues={{
          search: ""
        }}
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange
        }) => (
          
          <form onSubmit={handleSubmit} className="form">
           {withoutCard ?  ( <div className="form-group justify-content-start">
                <Field
                  type={type}
                  pattern={pattern}
                  maxLength={maxLength}
                  name="search"
                  component={InputAddons}
                  disabled={disabled}
                  placeholder={placeholder}
                  label= {label}
                  onlyNumber={onlyNumber}
                  dispatchedAction={dispatchedAction}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
            </div>):  (<Card>
            <CardBody>
            <div className="form-group justify-content-start">
                <Field
                  type={type}
                  pattern={pattern}
                  maxLength={maxLength}
                  name="search"
                  component={InputAddons}
                  disabled={disabled}
                  placeholder={placeholder}
                  label= {label}
                  onlyNumber={onlyNumber}
                  dispatchedAction={dispatchedAction}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
            </div>
            </CardBody>
          </Card>)
            }
          </form>
        )}
      </Formik>
      </ControlUIProvider>
  )
}

export default injectIntl(SearchFilter)
