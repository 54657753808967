/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import profileReducer from "./containers/profile/store/reducers"
import childrenReducer from "./containers/childrenSpace/store/reducers"
import VaccinationReducer from "./containers/vaccination/store/reducers"
import dashboardReducer from "./containers/home/store/reducers"
import citoyenReducer from "./containers/citoyen/store/reducers"
import dciReducer from "./containers/dci/store/reducers"
import mapiReducer from "./containers/mapi/store/reducers"
import listReducer from "./containers/list/store/reducers"
import specialtieReducer from "./containers/specialties/store/reducers"
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  childrenSpace: childrenReducer,
  profile: profileReducer,
  vaccination : VaccinationReducer,
  dashboard: dashboardReducer,
  citoyen: citoyenReducer,
  dci: dciReducer,
  mapi: mapiReducer,
  list: listReducer,
  specialtie: specialtieReducer,
})

