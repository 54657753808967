import React from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'

import { AuthenticationError, PermissionDeniedError, isPermittedPermission } from './../../helpers'
import { ContentRoute } from '../router'
import ResetPassword from 'src/modules/authentication/containers/ResetPassword'

const ProtectedRoute = ({ isAuthenticated = false, anonymous = true, can, isSuperuser,isDssb, permissions,hideforCnom=false,headerTokenType,...props }) => {
  const  isCnomUser  = headerTokenType ? true : false
  console.log("hideforCnom,isCnomUser",hideforCnom,isCnomUser)
  const {
    firstLogin,
  } = useSelector(
    (state) => ({
      firstLogin: state.common.auth.currentUser?.firstLogin,
    }),
    shallowEqual
  );
  
  if (!isAuthenticated && !anonymous) {
    throw new AuthenticationError()
  }

  if ((!isSuperuser && !isDssb && !isPermittedPermission(can, permissions))||(hideforCnom && isCnomUser)) {
    throw new PermissionDeniedError()
  }

  if (isAuthenticated && firstLogin === false){
    return(<ResetPassword/>)
  }

  return (<ContentRoute {...props} />)
}

const mapStateToProps = (state) => state.common.auth

export default connect(mapStateToProps)(ProtectedRoute)




