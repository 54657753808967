import { combinePathRoutes } from "src/helpers";
import routes from "src/routes";
import { lazy } from "react";
import { CREATE, LIST, UPDATE, VIEW ,VIEW_MENU} from "src/constants";
import { DCI } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";

const DCIComponent = lazy(() => import("./../components/card/DCI"));
const DCINewEdit = lazy(() => import("./../components/card/DCINewEdit"));
const DCIShow = lazy(() => import("./../components/card/DCIShow"));
const SpecialityShow = lazy(() =>
  import("./../components/card/DciSpecialtyShow")
);

export const dciList = {
  path: "/dci",
  component: DCIComponent,
  can: VaccinationManagement.module[DCI].permissions[VIEW_MENU],
};

export const dciCreate = {
  path: "/add/new",
  component: DCINewEdit,
  can: VaccinationManagement.module[DCI].permissions[CREATE],
};

export const dciEdit = {
  path: "/edit/:param/dci",
  component: DCINewEdit,
  can: VaccinationManagement.module[DCI].permissions[UPDATE],
};

export const dciShow = {
  path: "/dci/:param/show",
  component: DCIShow,
  can: VaccinationManagement.module[DCI].permissions[VIEW],
};
export const specialityShow = {
  path: "/dci/speciality/:param/show",
  component: SpecialityShow,
  can: VaccinationManagement.module[DCI].permissions[VIEW],
};

const path = routes.admin.path;

export default combinePathRoutes(
  { path },
  {
    specialityShow,
    dciShow,
    dciList,
    dciCreate,
    dciEdit,
  }
);
