import { ACTIONS } from "./../constants";
import { PURGE } from "redux-persist";

const initialState = {
  currentUser: null,
  permissions: null,
  token: null,
  isAuthenticated: false,
  isNameSpaceShown: false,
  phoneNumber: null,
  isSuperuser: false,
  isDssb: false,
  isLoading: false,
  isFetching: false,
  success: false,
  successFlash: false,
  changeOtpSuccess: false,
  error: null,
  successData: null,
  headerTokenType: null,
  audience: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {

    case ACTIONS.HIDE_NAME_SPACE_MDAOL: {
      return {
        ...state,
        isNameSpaceShown: false
      }
    }

    case ACTIONS.SHOW_NAME_SPACE_MODAL: {
      return {
        ...state,
        isNameSpaceShown: true
      }
    }

    case ACTIONS.CLEAR_ERROR: {
      return {
        ...state,
        isLoading: undefined,
        isFetching: false,
        success: false,
        successFlash: false,
        error: null,
        changeOtpSuccess:false
      };
    }

    case ACTIONS.SHOW_SUCCESS: {
      return {
        ...state,
        successFlash: true,
        error: null
      };
    }

    case ACTIONS.LOGIN_INIT: {
      console.log("***************ACTIONS.LOGIN_INIT*****************",{ ...state, isLoading: true, error: null })
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.LOGIN_SUCCEDED: {
      const { profile, access, permissions } = payload;
      console.log("paylooood", payload?.profile.phone)
      const token = access;

      return {

        ...state,
        isLoading: false,
        isAuthenticated: true,
        isNameSpaceShown: true,
        currentUser: profile?.user,
        isSuperuser: profile?.user.isSuperuser,
        isDssb: profile?.user.isDssb,
        token,
        phoneNumber: payload?.profile.phone,
        error: null,
        permissions: permissions,
      };
    }
    case ACTIONS.LOGIN_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.LOGOUT_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.LOGOUT_SUCCEDED: {
      return { ...initialState };
    }
    case PURGE: {
      return { ...initialState };
    }
    case ACTIONS.LOGOUT_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.UPDATE_PERSONAL_INFORMATION_SUCCEDED: {
      return { ...state, currentUser: { user: payload } };
    }

    case ACTIONS.FORGOT_PASSWORD_INIT: {
      return { ...state, isLoading: true, error: null, success: false };
    }
    case ACTIONS.FORGOT_PASSWORD_SUCCEDED: {
      return { ...state, isLoading: false, success: true, error: null };
    }
    case ACTIONS.FORGOT_PASSWORD_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.RESET_PASSWORD_INIT: {
      return { ...state, isLoading: true, error: null, success: false };
    }
    case ACTIONS.RESET_PASSWORD_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        currentUser: payload.user,
        isSuperuser: payload.user.isSuperuser,
        isDssb: payload.user.isDssb,
        error: null,
        success: true,
      };
    }
    case ACTIONS.RESET_PASSWORD_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }
    case ACTIONS.CHANGE_CURRENT_CENTER_INIT: {
      return { ...state, isLoading: true, error: null, success: false };
    }
    case ACTIONS.CHANGE_CURRENT_CENTER_SUCCEDED: {
        setTimeout(()=>{
          window.location.reload();
      }, 100); 
      console.log(payload)
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        isNameSpaceShown: false,
        currentUser: {
          ...state.currentUser,
          structuresDetails: state.currentUser.structuresDetails.map((item) => item.structure.id === payload.structure.id && item.structure.type === payload.structure.type ? payload : {...item,isDefault:false}),
        },
        error: null,
        permissions:payload?.permissions
      };
    }
    case ACTIONS.CHANGE_CURRENT_CENTER_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_INIT: {
      return { ...state, isLoading: true, error: null, successData: null }
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_SUCCEDED: {
      return { ...state, isLoading: false, error: null, successData: payload }
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_FAILED: {
      return { ...state, isLoading: false, error: payload, successData: null }
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_OTP_INIT: {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_OTP_SUCCEDED: {
      return { ...state, isLoading: false, error: null, changeOtpSuccess: true }
    }
    case ACTIONS.CHANGE_PHONE_NUMBER_OTP_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.GET_USER_DETAIL_INIT: {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.GET_USER_DETAIL_SUCCEDED: {
      return { ...state, isLoading: false, error: null, phoneNumber: payload.phone }
    }
    case ACTIONS.GET_USER_DETAIL_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.CNOM_LOGIN_INIT: {
      console.log("***************ACTIONS.CNOM_LOGIN_INIT*****************",{ ...state, isLoading: false, error: payload })
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.SET_HEADER_TOKEN_TYPE:
      return {
        ...state,
        headerTokenType: payload,
    };
    case ACTIONS.SET_HEADER_AUDIENCE:
      return {
        ...state,
        audience: payload,
    };

    default: {
      return state;
    }
  }
};



